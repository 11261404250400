import '../../css/index.css'
import { ChangeEvent, FC } from 'react'
import forEach from 'lodash/forEach'
import padStart from 'lodash/padStart'

import React, { useEffect, useState, useRef } from 'react'

interface Params {
  year: number
  month: number
  day: number
  hemisphere: Hemisphere
  color: 'silver' | 'gold'
}

const padZero = (value: number) => padStart(`${value}`, 2, '0')

const Loader: FC<{ style: 'light' | 'dark' }> = ({ style = 'light' }) => {
  const color = style === 'light' ? 'white' : 'black'
  return (
    <svg
      className='w-5 h-5 mr-3 -ml-1 text-white animate-spin'
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
    >
      <circle
        className='opacity-25'
        cx='12'
        cy='12'
        r='10'
        stroke={color}
        stroke-width='4'
      ></circle>
      <path
        className='opacity-75'
        fill={color}
        d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
      ></path>
    </svg>
  )
}

function getDocument() {
  return typeof document !== 'undefined' ? document : undefined
}

function getDocumentWidth() {
  return getDocument()?.documentElement.clientWidth
}

function getQueryAddress({ year, month, day, hemisphere, color }: Params) {
  let url = new URL(
    'https://lsbphg471c.execute-api.us-east-1.amazonaws.com/jewelry-production/render/moon/preview.png'
  )

  url.searchParams.append('year', `${year}`)
  url.searchParams.append('month', `${month}`)
  url.searchParams.append('day', `${day}`)
  url.searchParams.append('hemisphere', hemisphere)
  url.searchParams.append('color', color)

  const document = getDocument()

  if (document != null) {
    url.searchParams.append(
      'size',
      `${getDocumentWidth() * window.devicePixelRatio}`
    )
  }

  return url.toString()
}

function getDateComponents(date: Date) {
  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate(),
  }
}

function convertDateToIsoString(date: Date) {
  const { year, month, day } = getDateComponents(date)
  return `${padZero(year)}-${padZero(month)}-${padZero(day)}`
}

export const MoonJewelryPreview: FC = ({}) => {
  const today = new Date()
  const { year, month, day } = getDateComponents(today)
  const [state, setState] = useState<Params>({
    year,
    month,
    day,
    hemisphere: 'north',
    color: 'silver',
  })
  const [queryAddress, setQueryAddress] = useState(getQueryAddress(state))
  const [isLoading, setIsLoading] = useState(true)
  const [documentWidth, setDocumentWidth] = useState(getDocumentWidth())
  const imageRef = useRef<HTMLImageElement>(null)

  const onDateChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { currentTarget } = event
    const [year, month, day] = currentTarget.value
      .split('-')
      .map(c => Number(c))

    setState({ ...state, year, month, day })
  }

  const onColorChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const { currentTarget } = event
    const color = currentTarget.value as 'silver' | 'gold'

    setState({ ...state, color })
  }

  const onHemishphereChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const { currentTarget } = event
    const hemisphere = currentTarget.value as Hemisphere

    setState({ ...state, hemisphere })
  }

  const onImageLoad = () => {
    setIsLoading(false)
  }

  useEffect(() => {
    setIsLoading(true)
  }, [queryAddress])

  useEffect(() => {
    const queryAddress = getQueryAddress(state)
    setQueryAddress(queryAddress)

    let url = new URL(window.location.href)

    forEach(state, (value, key) => {
      url.searchParams.set(key, `${value}`)
    })

    history.replaceState({}, '', url.toString())

    if (!window.location.host.includes('localhost')) {
      window.postMessage(state, 'https://reports.ephemeris.co')
    }
  }, [state])

  useEffect(() => {
    window.addEventListener('resize', () => {
      setDocumentWidth(getDocumentWidth())
    })
  }, [])

  return (
    <section
      className={`
        w-full m-0 p-0
      `}
    >
      <style>
        {
          '\
        input[type="date"]::-webkit-inner-spin-button,\
        input[type="date"]::-webkit-calendar-picker-indicator {\
            display: none;\
            -webkit-appearance: none;\
        }\
      '
        }
      </style>
      <div>
        <div className='w-full'>
          {isLoading && (
            <div
              className='absolute'
              style={{
                width: documentWidth,
                height: documentWidth,
              }}
            >
              <div className='relative flex items-center justify-center w-full h-full bg-opacity-75 bg-darkBlue'>
                <Loader style='light' />
              </div>
            </div>
          )}
          <img
            ref={imageRef}
            src={queryAddress}
            onLoad={onImageLoad}
            className='min-w-full'
            style={{
              width: documentWidth,
              height: documentWidth,
            }}
          />
        </div>
        <div className={`flex items-center justify-between mt-4`}>
          <div>
            <label className='text-xs text-darkGold'>Date</label>
            <input
              type='date'
              defaultValue={convertDateToIsoString(today)}
              onChange={onDateChange}
              className={`
                block bg-transparent decoration-clone border-b-2 border-darkBlue focus:outline-none text-darkBlue text-sm
                md:text-lg
              `}
              style={{}}
            />
          </div>
          <div>
            <label className='text-xs text-darkGold'>Color</label>
            <select
              onChange={onColorChange}
              className={`
                block bg-transparent text-darkBlue border-b-2 border-darkBlue focus:outline-none text-sm
                md:text-lg
              `}
            >
              <option value='silver'>Silver</option>
              <option value='gold'>Gold</option>
            </select>
          </div>
          <div>
            <label className='text-xs text-darkGold'>Hemisphere</label>
            <select
              onChange={onHemishphereChange}
              className={`
                block bg-transparent text-darkBlue border-b-2 border-darkBlue focus:outline-none text-sm
                md:text-lg
              `}
            >
              <option value='north'>Northern</option>
              <option value='south'>Southern</option>
            </select>
          </div>
        </div>
      </div>
    </section>
  )
}
